import React from 'react'
import { useStaticQuery } from 'gatsby'
import Layout from "../components/layout"
import Posts from "../components/post_list"

const BlogPage = () => {
    const posts = useStaticQuery(graphql`
    query BlogPostsQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date]}) {
            edges {
                node {
                    id
                    frontmatter {
                        path
                        title
                        date
                        author
                        featured_image {
                            alt
                            title
                            src {
                                childImageSharp {
                                    fluid(maxWidth: 300) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    excerpt(pruneLength: 500)
                }
            }
        }
    }`)

    return (
    <Layout>
        <h1 className="text-center text-4xl font-semibold bg-gray-200">Articles</h1>
        <div className="px-10 sm:px-20 mb-6 sm:mb-12">
        <Posts postList={posts}></Posts>
        </div>
    </Layout>
)}

export default BlogPage
